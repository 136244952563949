<template lang="pug">
	div.page-statistics
		loading(:active.sync="busy", :is-full-page="true")
		.widget(v-if="filters")
			.widget-header
				h1.title Click List
			.widget-body
				form.form.form-inline.ov-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.toggle-filters(href="javascript: void(0);", @click="toggleFilters()")
						span Filters
						i.la.la-chevron-up
					.form-row-main
						ov-date-time-picker(v-model="filters.datePicker")
						select-advertiser(v-model="filters.advertiser_id")
						select-offer(v-model="filters.offer_id")
						select-publisher(v-model="filters.publisher_id")
						select-placement(v-model="filters.placement_id")
						.form-group
							label Subsource
							input.form-control(type="text", placeholder="23_5434245", v-model="filters.subsource")

					.form-row-end
						.form-group
							label &nbsp;
							// button.btn.btn-primary(type="submit") Go
							b-button-group
								b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
									b-dropdown-item(@click="loadData(true)")
										i.la.la-download
										| Export

				p.clearfix &nbsp;

				paginate(:paginator="paginate", @update-page-size="loadData")

				.ov-table-wrapper
					table.table.table-bordered.table-condensed
						thead
							tr
								th
									.text ID
								th
									.text Advertiser
								th
									.text Offer
								th
									.text.has-buttons Placement
										a.toggle-mode(href="javascript:void(0);", @click="togglePlacementViewMode()")
											i.la.la-arrows-h
								th
									.text Publisher
								th
									.text Subsource
								th
									.text.text-center Payout
								th
									.text ClickID
								th
									.text ClickIP
								th
									.text ClickTime
								th
									.text Conversion
								th
									.text Events
								th
									.text &nbsp;
								th
									.text Actions
						tbody
							tr(v-if="records.length===0")
								td(colspan="17") No matching records were found
							tr(v-for="r in records", v-bind:key="r.id")
								td {{ r.id }}
								td
									span(v-if="r.advertiser")
										entity(type="advertiser", :id="r.advertiser.id", :name.sync="r.advertiser.name", @click="appendEntity(r,'advertiser')")
								td
									span(v-if="r.offer")
										entity(type="offer", :id="r.offer.id", :name.sync="r.offer.name", @click="appendEntity(r,'offer')")
								td
									span(v-if="r.placement")
										entity(type="placement", :id="r.placement.id", :name.sync="r.placement.name", :mode="placementViewMode", @click="appendEntity(r,'placement')", :data="r.placement")
								td
									span(v-if="r.publisher")
										entity(type="publisher", :id="r.publisher.id", :name.sync="r.publisher.name", @click="appendEntity(r,'publisher')")
								td
									entity(v-if="r.subsource", type="sub1", :id="r.subsource.id", :name.sync="r.subsource.sub_id")
									entity(v-if="r.subsource2", type="sub2", :id="r.subsource2.id", :name.sync="r.subsource2.sub_id")
								td.text-center {{ r.payout }}
								td
									router-link.btn-edit(v-if="r.click", v-b-tooltip.hover.bottom, title="View",
										:to="{name:'click-view', params: {id: r.click.id}}") {{ r.click.id }}
								td
									span(v-if="r.click") {{ r.click.ip_fmt }}
								td
									span(v-if="r.click") {{ r.click.created_at }}
								td {{ r.created_at }}
								td {{ r.report_time }}
								td [{{ r.type_id }}] {{ r.type }}
								td.info-buttons
									a.btn(href="javascript:void(0)", :id="`pop-params-${r.id}`", v-b-tooltip.hover.bottom, title="Params" )
										i.la.la-info-circle
									b-popover.params-popover(:target="`pop-params-${r.id}`", placement="left", title="Params")
										pre.text-small {{ r.params }}
								td.actions
									button.btn.btn-secondary(type="button", v-b-tooltip.hover.bottom, title="Report", @click="report(r)")
										i.la.la-forward
									button.btn.btn-secondary(type="button", v-b-tooltip.hover.bottom, title="Convert", @click="convert(r)")
										i.la.la-forward
						tfoot
							tr
								td(colspan="6") &nbsp;
								td.text-center {{ summary.payout }}
								td(colspan="8") &nbsp;

				paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<style lang="scss">
.params-popover {
  max-width: 350px;
}

.info-buttons {
  a.btn {
    font-size: 16px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    padding: 0;
    text-align: center;
    border-radius: 100%;
  }
}
</style>
<script>
import Vue from 'vue';
import moment from 'moment';

export default {
  name: 'Statistics',
  computed: {
    USER() {
      return this.$store.state.user;
    },
    visibleAdvertiserOptions() {
      return this.advertiserOptions.filter((a) => {
        return this.advertiserOptionsArchive || a.status;
      });
    },
    visiblePublisherOptions() {
      return this.publisherOptions.filter((a) => {
        return this.publisherOptionsArchive || a.status;
      });
    },
  },
  async beforeRouteEnter(to, from, next) {
    let dateStartQ = to.query.date_start;

    let dateStartObj = null;
    if (dateStartQ) {
      dateStartObj = moment(dateStartQ, 'YYYY-MM-DD HH:mm');
    }
    if (!dateStartObj || !dateStartObj.isValid()) {
      dateStartObj = moment(
        moment()
          .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
          .format('DD/MM/YYYY') + ' 00:00',
        'DD/MM/YYYY HH:mm',
      );
    }
    let dateEndQ = to.query.date_end;

    let dateEndObj = null;
    if (dateEndQ) {
      dateEndObj = moment(dateEndQ, 'YYYY-MM-DD HH:mm');
    }
    if (!dateEndObj || !dateEndObj.isValid()) {
      dateEndObj = moment(
        moment()
          .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
          .format('DD/MM/YYYY') + ' 23:59',
        'DD/MM/YYYY HH:mm',
      );
    }

    let selectedAdvertiserOptions = await Vue.ovData.advertiser.getSelectedOptions(
      to.query.advertiser_id,
    );
    let selectedPublisherOptions = await Vue.ovData.publisher.getSelectedOptions(
      to.query.publisher_id,
    );
    let selectedOfferOptions = await Vue.ovData.offer.getSelectedOptions(to.query.offer_id);
    let selectedPlacementOptions = await Vue.ovData.placement.getSelectedOptions(
      to.query.placement_id,
    );

    next(async (vm) => {
      vm.filters.offer_id = selectedOfferOptions;
      vm.filters.publisher_id = selectedPublisherOptions;
      vm.filters.placement_id = selectedPlacementOptions;
      vm.filters.advertiser_id = selectedAdvertiserOptions;
      vm.filters.datePicker.dateRange.startDate = dateStartObj;
      vm.filters.datePicker.dateRange.endDate = dateEndObj;

      return vm;
    });
  },
  data() {
    return {
      busy: false,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 30,
        onPageChange: () => {
          this.loadData();
        },
      },
      records: [],
      subsource: '',
      summary: {
        payout: 0,
      },
      placementViewMode: 'mini',
      filters: {
        keyword: '',
        cohort: false,
        daily: false,
        offer_id: [],
        placement_id: [],
        publisher_id: [],
        advertiser_id: [],
        datePicker: {
          timezone: 0,
          dateRange: {
            startDate: null,
            endDate: null,
          },
        },
      },
      sortBy: {
        field: 'id',
        direction: 'desc',
        sort: null,
      },
      filtersExpanded: false,
    };
  },
  methods: {
    toggleFilters: function () {
      this.filtersExpanded = !this.filtersExpanded;
    },

    togglePlacementViewMode() {
      if (this.placementViewMode === 'mini') {
        this.placementViewMode = 'normal';
      } else {
        this.placementViewMode = 'mini';
      }
    },

    // async report(r) {
    // 	if (confirm('Are you sure you report this event?')) {
    // 		try {
    // 			let resp = await this.$ovReq.post('event/report', {id: r.id});
    // 			r.report_time = resp.report_time;
    // 			r.status = resp.status;
    // 		} catch (e){
    // 			console.error(e);
    // 		}
    // 	}
    // },

    appendEntity(record, entityType) {
      let entity = record[entityType];
      if (!entity || !this.filters[entityType + '_id']) {
        return;
      }
      for (let k in this.filters[entityType + '_id']) {
        if (+this.filters[entityType + '_id'].id === +entity.id) {
          return false;
        }
      }

      switch (entityType) {
        case 'placement':
        case 'offer':
          this.filters[entityType + '_id'].push({
            id: entity.id,
            name: `[${entity.id}] ${entity.name}`,
          });
          break;
        case 'advertiser':
        case 'publisher':
          this.filters[entityType + '_id'].push({
            v: entity.id,
            t: `[${entity.id}] ${entity.name}`,
          });
          break;
      }
      this.$forceUpdate();
    },

    async convert(r) {
      let confirmed = await this.$bvModal.msgBoxConfirm('Generate conversion from this event?');
      if (confirmed) {
        try {
          let resp = await this.$ovReq.post('event/generateConversion', { id: r.id });
          r.report_time = resp.report_time;
          r.status = resp.status;
        } catch (e) {
          console.error(e);
        }
      }
    },

    async loadData(doExport) {
      if (this.busy) {
        return;
      }
      let params = {
        export: doExport ? 1 : 0,
        page: this.paginate.page,
        page_size: this.paginate.limit,
        subsource: this.filters.subsource,
        //click_from: this.filters.click_from ? moment(this.filters.click_from).format('YYYY-MM-DD') : '',

        advertiser_id: this.filters.advertiser_id.map((r) => r.v).join(','),
        publisher_id: this.filters.publisher_id.map((r) => r.v).join(','),

        offer_id: this.filters.offer_id.map((r) => r.id).join(','),
        placement_id: this.filters.placement_id.map((r) => r.id).join(','),

        timezone: this.filters.datePicker.timezone,
        date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
      };
      this.busy = true;
      try {
        let resp = await this.$ovReq.get('click/getList', { params });
        if (doExport) {
          window.open(resp.path);
        } else {
          this.records = resp.records;
          this.paginate.total = resp.total;
          this.paginate.numPages = Math.max(
            Math.ceil(this.paginate.total / this.paginate.limit),
            1,
          );
          this.summary = resp.summary;
        }
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
};
</script>
